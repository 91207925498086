import React, { useCallback } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon';
import CircularProgress from '../CircularProgress';
import assignDisplayName from '../util/assignDisplayName';
import pick from '../util/pick';

import './style.css';

const ELPROPS = [
  'onFocus',
  'onBlur',
  'title',
  'autoFocus',
  'tabIndex',
  'form',
  'aria-checked',
  'aria-pressed',
  'role',
  'onKeyDown',
  'id',
  'type'
];

export default function Button(props) {
  const {
    block,
    icon,
    text,
    value,
    onClick,
    className,
    appearance,
    disabled,
    loading
  } = props;

  const elProps = pick(props, ...ELPROPS);

  const iconEl = icon ? <Icon icon={icon} className='Button-icon' /> : null;

  const loadingEl = loading ? (
    <CircularProgress className='Button-loading' />
  ) : null;

  const clickHandler = useCallback(
    (e) => onClick(value || e),
    [onClick, value]
  );

  return (
    <button
      {...elProps}
      disabled={disabled || loading}
      onClick={onClick ? clickHandler : null}
      className={cn('Button', className, {
        [`is-${appearance}`]: !!appearance,
        'icon-only': !text && icon,
        'is-block': block
      })}
    >
      {loadingEl || iconEl}
      {text ? <span className='Button-text'>{text}</span> : null}
    </button>
  );
}

assignDisplayName(Button);

Button.propTypes = {
  /** WAI-ARIA title */
  title: PT.string,
  /** CSS class for a root element */
  className: PT.string,
  /** Controls appearance of a button */
  appearance: PT.oneOf(['accent', 'solid']),
  /** Whatever button is a block (full-width-style) */
  block: PT.bool,
  /** Value this value will be used **INSTEAD** of Event */
  value: PT.oneOfType([PT.string, PT.number]),
  /** Text of a button */
  text: PT.string,
  /** Optional icon or loader */
  icon: PT.string,
  /** Controls element focusability */
  tabIndex: PT.number,
  /** HTML Button type attribute. */
  type: PT.oneOf(['submit', 'reset', 'button']),
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** On click handler */
  onClick: PT.func,
  /** This function will be fired if button is under focus */
  onFocus: PT.func,
  /** This function will be fired when button losing focus */
  onBlur: PT.func,
  /** Keydown event handler  */
  onKeyDown: PT.func,
  /** HTML ID attribute */
  id: PT.string,
  /** Indicates whatever button should represent loading state (changes icon), aslo disables button */
  loading: PT.bool,
  /** ID of form element to associate the button with  */
  form: PT.string,
  /** WAI-Aria pressed state */
  'aria-pressed': PT.bool,
  /** WAI-Aria checked state */
  'aria-checked': PT.bool,
  /** WAI-Allowed ARIA button roles */
  role: PT.oneOf([
    'checkbox',
    'link',
    'menuitem',
    'menuitemcheckbox',
    'menuitemradio',
    'option',
    'radio',
    'switch',
    'tab'
  ])
};
