import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import useFormInterface from '../hooks/useFormInterface';
import assignDisplayName from '../util/assignDisplayName';
import pick from '../util/pick';
import { valueShape, optionShape } from '../proptypes';
import Icon from '../Icon';
import FormControl from '../FormControl';
import Select from '../Select';

import './style.css';

const ELPROPS = [
  'value',
  'name',
  'options',
  'disabled',
  'required',
  'isLoading',
  'clearable',
  'placeholder',
  'removeSelected',
  'valueRenderer',
  'optionRenderer',
  'onFocus'
];

export default function Dropdown(props) {
  const {
    id,
    icon,
    label,
    inline,
    message,
    error,
    className,
    onChange,
    onBlur
  } = props;
  const elProps = pick(props, ...ELPROPS);
  const [pristine, innerError, handlers] = useFormInterface({
    onChange,
    onBlur
  });

  return (
    <FormControl
      id={id}
      className={cn('Dropdown', className)}
      label={label}
      message={message}
      error={error || innerError}
      inline={inline}
    >
      <Select id={id} pristine={pristine} {...elProps} {...handlers} />
      {icon && (
        <span className='FormControl-icon'>
          <Icon icon={icon} />
        </span>
      )}
    </FormControl>
  );
}

assignDisplayName(Dropdown);

Dropdown.propTypes = {
  /** Id for input */
  id: PT.string.isRequired,
  /** Input label */
  label: PT.string,
  /** Additional description for a field will be overriden if [error] provided */
  message: PT.node,
  /** Value of input REQUIRED */
  value: PT.oneOfType([PT.arrayOf(valueShape), valueShape]),
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** CSS Class for a root element */
  className: PT.string,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** Placeholder for an input */
  placeholder: PT.string,
  /** Array of options to choose */
  options: PT.arrayOf(PT.oneOfType([PT.string, optionShape])),
  /** Whatever specific icon should be added before input */
  icon: PT.string,
  /** Way to customize your selected value(s) to render: function (option) {} */
  valueRenderer: PT.func,
  /** Way to customize your options to render: function (option) {} */
  optionRenderer: PT.func,
  /** Indicates whatever contens are being loaded */
  isLoading: PT.bool,
  /** value to use when you clear the control */
  resetValue: valueShape,
  /** should it be possible to reset value */
  clearable: PT.bool,
  /** Determines if selected options should be filtered out from a list */
  removeSelected: PT.bool,
  /** Defines whatever label should be on one line with a Dropdown, may accept any CSS value as flex-basis for input or auto othervice */
  inline: PT.oneOfType([PT.bool, PT.string]),
  /** applies HTML5 required attribute when needed */
  required: PT.bool,
  /** Explicitly set error for given control */
  error: PT.string
};
