import { useEffect, useCallback } from 'react';
import { CTX_MENU_EVT } from '../const';

export default function useCtxChannel(handler, emitOnInit) {
  useEffect(() => {
    if (emitOnInit) {
      document.body.dispatchEvent(new Event(CTX_MENU_EVT));
    }
    document.body.addEventListener(CTX_MENU_EVT, handler);
    return () => {
      document.body.removeEventListener(CTX_MENU_EVT, handler);
    };
  }, [handler, emitOnInit]);

  return useCallback(
    () => document.body.dispatchEvent(new Event(CTX_MENU_EVT)),
    []
  );
}
