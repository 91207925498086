import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import runIfExists from '../util/runIfExists';
import debounce from '../util/debounce';
import useBodyClick from '../hooks/useBodyClick';
import useCtxChannel from '../hooks/useCtxChannel';
import { valueShape, optionShape } from '../proptypes';

import Icon from '../Icon';
import CircularProgress from '../CircularProgress';

import './style.css';

export function SelectOption(props) {
  const {
    id,
    role,
    onSubmit,
    tabIndex,
    selected,
    className,
    onKeyDown,
    option,
    index,
    renderer
  } = props;

  const label = option.label || option;

  const clickHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      runIfExists(onSubmit, option, index);
    },
    [onSubmit, option, index]
  );

  const keyDownHandler = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        runIfExists(onSubmit, option, index);
      } else {
        runIfExists(onKeyDown, e);
      }
    },
    [onSubmit, option, index, onKeyDown]
  );

  return (
    <li
      onClickCapture={onSubmit ? clickHandler : null}
      tabIndex={tabIndex}
      role={role}
      id={id}
      aria-selected={selected || undefined}
      aria-label={label}
      className={className}
      onKeyDown={keyDownHandler}
    >
      {renderer ? renderer(option, index) : label}
    </li>
  );
}
SelectOption.propTypes = {
  className: PT.string,
  renderer: PT.func,
  selected: PT.bool,
  onSubmit: PT.func,
  onKeyDown: PT.func,
  tabIndex: PT.number,
  id: PT.string.isRequired,
  role: PT.string.isRequired,
  index: PT.number.isRequired,
  option: PT.shape({
    value: valueShape.isRequired,
    label: PT.string.isRequired
  }).isRequired
};

export function SelectOptionList(props) {
  const { id, children, className } = props;
  const $root = useRef();

  const adjustPositioning = useCallback(() => {
    if ($root.current) {
      const value = $root.current.getAttribute('data-position').split(' ');
      const { left, right, top, bottom } =
        $root.current.getBoundingClientRect();

      if (left < 0) {
        value[0] = 'left';
      } else if (right > window.outerWidth) {
        value[0] = 'right';
      }

      if (top < 0) {
        value[1] = 'bottom';
      } else if (bottom > window.outerHeight) {
        value[1] = 'top';
      }

      $root.current.setAttribute('data-position', value.join(' '));
    }
  }, []);

  useEffect(() => {
    adjustPositioning();
    const handler = debounce(adjustPositioning, 150);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [adjustPositioning]);

  return (
    <ul
      id={id}
      ref={$root}
      tabIndex={-1}
      role='listbox'
      data-position='right bottom'
      className={className}
    >
      {children}
    </ul>
  );
}
SelectOptionList.propTypes = {
  id: PT.string.isRequired,
  children: PT.node,
  className: PT.string
};

export default function Select(props) {
  const {
    id,
    value,
    name,
    options,
    disabled,
    required,
    isLoading,
    clearable,
    placeholder,
    className,
    pristine,
    removeSelected,
    valueRenderer,
    optionRenderer,
    closeOthersOnOpen,
    onChange,
    onBlur,
    onFocus
  } = props;
  const $root = useRef();
  const $control = useRef();
  const listId = `${id}-options`;

  const { isMulti, selected, unselected, selectedKeys } = useMemo(() => {
    return (options || []).reduce(
      (acc, option) => {
        if (
          (acc.isMulti && value.indexOf(option.value) > -1) ||
          value === option.value
        ) {
          acc.selected.push(option);
          acc.selectedKeys[option.value] = true;
        } else {
          acc.unselected.push(option);
        }
        return acc;
      },
      {
        selected: [],
        unselected: [],
        selectedKeys: {},
        isMulti: Array.isArray(value)
      }
    );
  }, [value, options]);

  const [open, setOpen] = useState(false);

  const fireChange = useCallback(
    (val) =>
      onChange({
        target: {
          id,
          name,
          value: val
        }
      }),
    [id, name, onChange]
  );

  const closeMenu = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  const dispatch = useCtxChannel(closeMenu);

  const openMenu = useCallback(() => {
    if (!isLoading) {
      if (closeOthersOnOpen) {
        dispatch();
      }
      setOpen(true);
    }
  }, [isLoading, dispatch, closeOthersOnOpen]);

  useBodyClick($root, closeMenu);

  const onControlKeyDown = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        openMenu();
      } else if (e.code === 'ArrowDown') {
        e.preventDefault();
        $root.current.querySelector(`#${listId}`).firstChild.focus();
      }
    },
    [openMenu, listId]
  );

  const onControlFocus = useCallback(
    (e) => {
      openMenu();
      runIfExists(onFocus, e);
    },
    [openMenu, onFocus]
  );

  const onControlBlur = useCallback(
    (e) => {
      runIfExists(onBlur, e);
    },
    [onBlur]
  );

  const onOption = useCallback(
    (option) => {
      fireChange(isMulti ? [...value, option.value] : option.value);
      if (!isMulti) {
        $control.current.focus();
        setOpen(false);
      } else {
        setOpen(true);
      }
    },
    [fireChange, isMulti, value]
  );

  const onValue = useCallback(
    (option) => {
      fireChange(value.filter((v) => v !== option.value));
    },
    [fireChange, value]
  );

  const resetValue = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      fireChange(isMulti ? [] : null);
      $control.current.focus();
    },
    [isMulti, fireChange]
  );

  const focusControlFn = useCallback((e) => {
    if (e.code === 'ArrowDown') {
      if (e.target.nextElementSibling) {
        e.preventDefault();
        e.target.nextElementSibling.focus();
      }
    } else if (e.code === 'ArrowUp') {
      if (e.target.previousElementSibling) {
        e.preventDefault();
        e.target.previousElementSibling.focus();
      } else {
        $control.current.focus();
        setOpen(false);
      }
    }
  }, []);

  return (
    <div
      className={cn('Select', className, {
        'is-multiple': isMulti,
        'is-disabled': disabled
      })}
      ref={$root}
    >
      <input
        type='hidden'
        required={required}
        data-pristine={pristine}
        value={isMulti ? value.join(',') : value || ''}
        name={name}
        id={id}
      />
      <div
        className='Select-control'
        tabIndex={0}
        ref={$control}
        disabled={disabled}
        role='combobox'
        aria-expanded={open}
        aria-owns={listId}
        aria-controls={listId}
        aria-activedescendant={null}
        onClick={!disabled ? openMenu : null}
        onFocus={!disabled ? onControlFocus : null}
        onBlur={!disabled ? onControlBlur : null}
        onKeyDown={!disabled ? onControlKeyDown : null}
      >
        <ul className='Select-holder'>
          {selected.length ? (
            selected.map((option, index) => {
              return (
                <SelectOption
                  key={`value-${option.value}`}
                  id={`value-${id}-${index}`}
                  index={index}
                  option={option}
                  role='value'
                  onSubmit={isMulti ? onValue : null}
                  selected
                  className='Select-value'
                  renderer={valueRenderer}
                />
              );
            })
          ) : (
            <li key='novalue' className='Select-no-value'>
              {placeholder}
            </li>
          )}
        </ul>
        {clearable && selected.length ? (
          <button
            disabled={disabled}
            className='Select-pointer Select-clear'
            onClick={resetValue}
            title='Reset value'
          >
            <Icon icon='cross' />
          </button>
        ) : (
          <div className='Select-pointer'>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
            )}
          </div>
        )}
      </div>
      {open ? (
        <SelectOptionList
          className='Select-list layout-contextmenu'
          id={listId}
        >
          {(removeSelected ? unselected : options).map((option, index) => {
            return (
              <SelectOption
                key={`option-${option.value}`}
                id={`option-${id}-${index}`}
                index={index}
                option={option}
                tabIndex={0}
                role='option'
                onSubmit={onOption}
                onKeyDown={focusControlFn}
                className={cn('Select-option', {
                  'is-selected': !removeSelected && selectedKeys[option.value]
                })}
                renderer={optionRenderer}
              />
            );
          })}
        </SelectOptionList>
      ) : null}
    </div>
  );
}

assignDisplayName(Select);

Select.propTypes = {
  /** Id for input */
  id: PT.string.isRequired,
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** CSS Class for a root element */
  className: PT.string,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** Placeholder for an input */
  placeholder: PT.string,
  /** Indicates whatever contens are being loaded */
  isLoading: PT.bool,
  /** applies HTML5 required attribute when needed */
  required: PT.bool,
  /** should it be possible to reset value */
  clearable: PT.bool,
  /** Current value of Select */
  value: PT.oneOfType([valueShape, PT.arrayOf(valueShape)]),
  /** Array of options to choose */
  options: PT.arrayOf(optionShape).isRequired,
  /** Determines if selected options should be filtered out from a list */
  removeSelected: PT.bool,
  /** Way to customize your selected value(s) to render: function (option) {} */
  valueRenderer: PT.func,
  /** Way to customize your options to render: function (option) {} */
  optionRenderer: PT.func,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Indicates whatever this field was touched (for validation purposes) */
  pristine: PT.bool,
  /** Close other popups and dropdowns on open */
  closeOthersOnOpen: PT.bool
};

Select.defaultProps = {
  closeOthersOnOpen: true
};
