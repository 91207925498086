import React from 'react';
import { createPortal } from 'react-dom';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import isValidString from '../util/isValidString';
import Button from '../Button';
import Overlay from '../Overlay';

import './style.css';

export default function Dialog(props) {
  const {
    id,
    show,
    title,
    children,
    header,
    footer,
    onClose,
    footerCloseButton,
    className,
    maxWidth,
    maxHeight,
    zIndex,
    wrapContent,
    hideOnOverlayClick
  } = props;
  if (!show) return null;
  return createPortal(
    <div className='layout-centered' style={{ '--custom-z-index': zIndex }}>
      <section
        id={id}
        className={cn('Dialog', 'layout-centered-container', className)}
        style={{
          '--dialog-width': maxWidth,
          '--dialog-height': maxHeight
        }}
        aria-modal='true'
        role='dialog'
      >
        <header className='Dialog-header'>
          <h1 className='Dialog-title'>{title}</h1>
          <Button
            icon='cross'
            title={`Close ${title} dialog window`}
            className='Dialog-close'
            onClick={onClose}
          />
          {header}
        </header>
        {wrapContent ? (
          <div
            className={cn('Dialog-content', {
              [wrapContent]: isValidString(wrapContent)
            })}
          >
            {children}
          </div>
        ) : (
          children
        )}
        {footer || footerCloseButton ? (
          <footer className='Dialog-footer'>
            {footer}
            {footerCloseButton ? (
              <Button
                text={
                  isValidString(footerCloseButton) ? footerCloseButton : 'Close'
                }
                appearance='solid'
                onClick={onClose}
              />
            ) : null}
          </footer>
        ) : null}
      </section>
      <Overlay onClick={hideOnOverlayClick ? onClose : null} />
    </div>,
    document.body
  );
}

assignDisplayName(Dialog);

Dialog.defaultProps = {
  show: false,
  wrapContent: true,
  hideOnOverlayClick: true
};

Dialog.propTypes = {
  /** Id for Portal container element */
  id: PT.string.isRequired,
  /** Simple text title for header */
  title: PT.string.isRequired,
  /** CSS class for root element */
  className: PT.string,
  /** Show Dialog or not tells Portal component show it contents */
  show: PT.bool,
  /** Defines if [onClose] handler should passed as Overlay click */
  hideOnOverlayClick: PT.bool,
  /** Handler attached to Close button and Overlay click events */
  onClose: PT.func.isRequired,
  /** Dialog contents */
  children: PT.node,
  /** Addiditional content for header */
  header: PT.node,
  /** Content of a footer, any React element will work */
  footer: PT.node,
  /** CSS-valid value that will define maximum width of modal window */
  maxWidth: PT.string,
  /** CSS-valid value that will define maximum height of modal window */
  maxHeight: PT.string,
  /** [z-index] for root layout element, any string or number */
  zIndex: PT.oneOfType([PT.string, PT.number]),
  /** Wrap content in <div> if [true] if string - additional className for given <div> */
  wrapContent: PT.oneOfType([PT.bool, PT.string]),
  /** Render close button in footer, `Button` text if string */
  footerCloseButton: PT.oneOfType([PT.bool, PT.string])
};
