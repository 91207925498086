import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect
} from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';
import pick from '../util/pick';
import debounce from '../util/debounce';
import useBodyClick from '../hooks/useBodyClick';
import useCtxChannel from '../hooks/useCtxChannel';
import Button from '../Button';

import './style.css';

export default function Popup(props) {
  const {
    className,
    popupClassName,
    children,
    getScrollParent,
    getXBoundary,
    getYBoundary
  } = props;

  const [open, setOpen] = useState(false);

  const $el = useRef();
  const $popup = useRef();

  const onTriggerClick = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const closePopup = useCallback(() => setOpen(false), []);

  useBodyClick($el, closePopup);

  useCtxChannel(closePopup);

  const adjustPostion = useCallback(
    (prev, popupRect) => {
      const value = prev.split(' ');
      const { left, width, bottom, height } = popupRect;
      const yBoundary = getYBoundary();
      const xBoundary = getXBoundary();
      if (yBoundary < bottom) {
        value[0] = 'top';
      } else if (value[0] === 'top' && yBoundary >= bottom + height) {
        value[0] = 'bottom';
      }

      if (left < xBoundary) {
        value[1] = 'left';
      } else if (value[1] === 'left' && xBoundary <= left - width) {
        value[1] = 'right';
      }

      return value;
    },
    [getYBoundary, getXBoundary]
  );

  useEffect(() => {
    let $parent = null;
    const handler = debounce(() => {
      if (!$el || !$el.current) return;
      const newValue = adjustPostion(
        $el.current.getAttribute('data-pos'),
        $popup.current.getBoundingClientRect()
      );

      $el.current.setAttribute('data-pos', newValue.join(' '));
    }, 70);

    if (open && getScrollParent) {
      $parent = getScrollParent();
      if ($parent) {
        $parent.addEventListener('scroll', handler);
      }
    }
    return () => {
      if (open && $parent) {
        $parent.removeEventListener('scroll', handler);
        $parent = null;
      }
    };
  }, [open, getScrollParent, adjustPostion]);

  useLayoutEffect(() => {
    if (open) {
      const newValue = adjustPostion(
        $el.current.getAttribute('data-pos'),
        $popup.current.getBoundingClientRect()
      );

      $el.current.setAttribute('data-pos', newValue.join(' '));
    }
  }, [open, adjustPostion]);

  return (
    <div ref={$el} className={cn('Popup', className)} data-pos='bottom right'>
      <Button
        {...pick(
          props,
          'icon',
          'appearance',
          'loading',
          'title',
          'text',
          'disabled'
        )}
        className='Popup-trigger'
        open={open}
        onClick={onTriggerClick}
      />
      {open && (
        <div
          ref={$popup}
          className={cn('Popup-content', 'layout-contextmenu', popupClassName)}
        >
          {children}
        </div>
      )}
    </div>
  );
}

assignDisplayName(Popup);

Popup.defaultProps = {
  icon: 'menu',
  getXBoundary: () => 0,
  getYBoundary: () => window.outerHeight,
  getScrollParent: () => window
};

Popup.propTypes = {
  /** Unique ID attribute for root DOM element */
  id: PT.string,
  /** Horizontal boundary getter - determines when popup should flip */
  getXBoundary: PT.func,
  /** Vertical boundary getter - determines when popup should flip */
  getYBoundary: PT.func,
  /** Element getter to track scroll of `window` by default */
  getScrollParent: PT.func,
  /** CSS class for a root component */
  className: PT.string,
  /** CSS class for a popup that rendered in portal */
  popupClassName: PT.string,
  /** Content of a popup to render */
  children: PT.node,
  /** Icon for <Button> trigger */
  icon: PT.string,
  /** <Button> trigger text value */
  text: PT.string,
  /** Whatever trigger button is disabled */
  disabled: PT.bool,
  /** Whatever button should have loading state due to action */
  loading: PT.bool,
  /** Controls appearance of a button */
  appearance: PT.string,
  /** title attribute fron <Button> */
  title: PT.string
};
