import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import pick from '../util/pick';
import useFormInterface from '../hooks/useFormInterface';
import assignDisplayName from '../util/assignDisplayName';
import { TEXT_INPUT_TYPES } from '../const';
import Icon from '../Icon';
import FormControl from '../FormControl';

import './style.css';

const ELPROPS = [
  'name',
  'type',
  'value',
  'disabled',
  'placeholder',
  'autoFocus',
  'min',
  'max',
  'required',
  'pattern',
  'onFocus',
  'tabIndex',
  'onKeyUp',
  'onKeyDown'
];

export default function TextInput(props) {
  const {
    id,
    icon,
    label,
    message,
    inline,
    error,
    autocomplete,
    className,
    onChange,
    onBlur
  } = props;
  const elProps = pick(props, ...ELPROPS);
  const [pristine, innerError, handlers] = useFormInterface({
    onChange,
    onBlur
  });
  return (
    <FormControl
      id={id}
      className={cn('TextInput', className)}
      label={label}
      message={message}
      error={error || innerError}
      inline={inline}
    >
      <input
        {...elProps}
        id={id}
        data-pristine={pristine}
        autoComplete={autocomplete || undefined}
        {...handlers}
      />
      {icon && (
        <span className='FormControl-icon'>
          <Icon icon={icon} />
        </span>
      )}
    </FormControl>
  );
}

assignDisplayName(TextInput);

TextInput.propTypes = {
  /** Id for input */
  id: PT.string.isRequired,
  /** Input label */
  label: PT.string,
  /** Additional description for a field will be overriden if [error] provided */
  message: PT.node,
  /** Input type corresponds to specific input type */
  type: PT.oneOf(TEXT_INPUT_TYPES).isRequired,
  /** Value of input REQUIRED */
  value: PT.oneOfType([PT.string, PT.number, PT.bool]).isRequired,
  /** change handler REQUIRED */
  onChange: PT.func.isRequired,
  /** Name of input REQUIRED */
  name: PT.string.isRequired,
  /** Controls element focusability */
  tabIndex: PT.number,
  /** CSS Class for a root element */
  className: PT.string,
  /** This function will be fired if input is under focus */
  onFocus: PT.func,
  /** This function will be fired when input losing focus */
  onBlur: PT.func,
  /** Keydown event listener */
  onKeyDown: PT.func,
  /** Keyup event listener */
  onKeyUp: PT.func,
  /** Indicates whatever this control is enabled or not */
  disabled: PT.bool,
  /** Placeholder for an input */
  placeholder: PT.string,
  /** Pattern HTML proerty directly passed to DOM element */
  pattern: PT.oneOfType([PT.string, PT.instanceOf(RegExp)]),
  /** Min possible value. Only aplicable for input[number] or [date] */
  min: PT.oneOfType([PT.string, PT.number]),
  /** Max possible value. Only aplicable for input[number] or [date] */
  max: PT.oneOfType([PT.string, PT.number]),
  /** Indicate that this input is required and cannot be omitted */
  required: PT.bool,
  /** Corresponds to [autocomplete] input attribute */
  autocomplete: PT.string,
  /** Whatever specific icon should be added before input */
  icon: PT.string,
  /** Defines whatever label should be on one line with a Input, may break look, use with caution, may accept any CSS value as flex-basis for input or auto othervice */
  inline: PT.oneOfType([PT.bool, PT.string]),
  /** Explicitly set error for given control */
  error: PT.string
};
