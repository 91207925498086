/**
 * Simple function that allows to pick subset of data from Object *except* given as rest agruments as values to omit
 * @param {Object} object target object to pick data from
 * @param {...String} paths keys or path of keys descriptions to avoid picking
 * @return {Object}
 */
export default function omit(target, ...paths) {
  let i = -1;
  const result = { ...target };

  // eslint-disable-next-line no-plusplus
  while (++i < paths.length) {
    delete result[paths[i]];
  }
  return result;
}
