import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

export default function Overlay(props) {
  const { onClick, style, className } = props;
  return (
    <div
      style={style}
      onClick={onClick}
      aria-hidden
      className={cn('layout-overlay', className, {
        'is-clickable': !!onClick
      })}
    />
  );
}

assignDisplayName(Overlay);

Overlay.propTypes = {
  /** CSS Class for element */
  className: PT.string,
  /** Optional click handler for an Element */
  onClick: PT.func,
  /** CSS styles as direct assignment */
  style: PT.objectOf(PT.string)
};
