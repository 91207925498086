import React, { useCallback, useEffect } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import { NOTIFICATION_TYPES } from '../const';
import assignDisplayName from '../util/assignDisplayName';
import Icon from '../Icon';

import './style.css';

export default function Notification(props) {
  const { icon, children, className, type, onClick, title, id, delay } = props;

  const clickHanlder = useCallback(() => onClick({ id }), [onClick, id]);

  useEffect(() => {
    let timer;
    if (clickHanlder && delay) {
      timer = setTimeout(clickHanlder, delay);
    }
    return () => window.clearTimeout(timer);
  }, [clickHanlder, delay]);

  return (
    <div
      className={cn('Notification', className, `theme-${type}`)}
      role='presentation'
      onClick={clickHanlder}
    >
      {icon ? <Icon className='Notification-icon' icon={type} /> : null}
      <div className='Notification-content'>
        {title ? <h4 className='Notification-title'>{title}</h4> : null}
        {children}
      </div>
    </div>
  );
}

assignDisplayName(Notification);

Notification.defaultProps = {
  icon: true
};

Notification.propTypes = {
  /** Unique id of a notification */
  id: PT.number.isRequired,
  /** Number of ms when onClick would be invoked automatically */
  delay: PT.number,
  /** Optional title attribute */
  title: PT.string,
  /** Whatever show the status icon */
  icon: PT.bool,
  /** Contentes to show, text or any React element */
  children: PT.node,
  /** Classname for a root element for customization */
  className: PT.string,
  /** Type of notification, level of priority */
  type: PT.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired,
  /** Optional click handler for root element */
  onClick: PT.func
};
