import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import assignDisplayName from '../util/assignDisplayName';

import './style.css';

export default function Image(props) {
  const { src, lazy, ratio, id, fallback, className, onLoad, alt, onError } =
    props;
  return (
    <figure
      id={id}
      className={cn('Image', className)}
      style={{
        '--aspect-ratio': ratio,
        '--Image-fallback': fallback ? `url(${fallback})` : undefined
      }}
    >
      <img
        alt={alt}
        src={src}
        onError={onError}
        onLoad={onLoad}
        aria-hidden={!alt || undefined}
        loading={lazy ? 'lazy' : undefined}
      />
    </figure>
  );
}

assignDisplayName(Image);

Image.defaultProps = {
  ratio: '16/9'
};

Image.propTypes = {
  /** URL of image to load */
  src: PT.string,
  /** HTML ID attribute applied to <figure> */
  id: PT.string,
  /** aspect ration of image for repaint optimization trick */
  ratio: PT.string,
  /** CSS class for a root element applied to <figure> */
  className: PT.string,
  /** WAI-ARIA description of image <img/>  */
  alt: PT.string,
  /** Corresponds to [loading] attribute for <img> tag */
  lazy: PT.bool,
  /** Fallback image that should be shown if image not found */
  fallback: PT.string,
  /** Function executed if image loading finished */
  onLoad: PT.func,
  /** Function executed if image loading encountered error */
  onError: PT.func
};
